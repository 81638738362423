<template>
  <div>
    <div class="service_list_box">
      <div class="service_item_list">
        <div class="app_title_box">
          <h4>Contact List</h4>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="showModal"
          >
            New Contact
          </b-button>
        </div>
        <div class="row">
          <div class="col-md-12 mb-4">
            <div class="sorting_view_main_box">
              <div class="title_view">
                <!-- <h4 class="search_result_text">
                  Total Contacts({{ totalRows }})
                </h4> -->
                <!-- v-b-tooltip.hover.top="'sort alphabetically'" -->
                <div

                  class="sort_item_box_view"
                  @click="sortData"
                >
                  <img
                    v-if="sort_type === 'DESC'"
                    :src="SortDown"
                    alt="sort"
                  >
                  <img
                    v-if="sort_type === 'ASC'"
                    v-b-tooltip.hover.top="'sort alphabetically'"
                    :src="SortUp"
                    alt="sort"
                  >
                </div>
              </div>

              <div class="search-input-view">
                <b-form-input
                  id="h-search-keyword"
                  v-model="searchTerm"
                  type="text"
                  placeholder="search contact"
                  class="form-control"
                  style="min-width: 450px;"
                />
              </div>
            </div>
          </div>
          <div
            v-for="(item, index) in paginatedData"
            :key="index"
            class="col-md-3"
            @click="showDetails(item)"
          >
            <div class="gts-contact-card-view">
              <b-avatar
                variant="light-success"
                size="70"
              />
              <h4>{{ item.full_name }}</h4>
              <p>{{ item.address }}
                <br>{{ item.city }} , {{ states.filter(elm => elm.id === item.state_id)[0].abbreviation }} {{ item.zip_code }}</p>
              <span>{{ item.phone }}</span>
              <span>{{ item.email }}</span>

            </div>
          </div>
        </div>
        <div
          v-if="paginatedData.length > 12 || currentPage > 1"
          class="pagination_box"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="lg"
            class="my-0"
            @change="onPageChanged"
          />
        </div>
      </div>

    </div>
    <b-modal
      id="app-modal"
      :title="is_update ? 'Update Contact' : 'Add New Contact'"
      hide-footer
      size="lg"
      @hide="hide"
    >
      <b-card-text>
        <validation-observer ref="contactForm">
          <b-form @submit.prevent="is_update ? updateContact() : createContact()">
            <b-row>
              <!-- first name -->
              <b-col cols="12">
                <b-form-group
                  label="Name"
                  label-for="v-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="v-name"
                      v-model="contact.full_name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Email"
                  label-for="v-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="v-email"
                      v-model="contact.email"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Phone"
                  label-for="v-phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="phone"
                    rules="required"
                  >
                    <b-form-input
                      id="v-phone"
                      v-model="contact.phone"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Phone"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Address"
                  label-for="v-address"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="address"
                    rules="required"
                  >
                    <b-form-input
                      id="v-address"
                      v-model="contact.address"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="City"
                  label-for="v-city"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="city"
                    rules="required"
                  >
                    <b-form-input
                      id="v-city"
                      v-model="contact.city"
                      :state="errors.length > 0 ? false:null"
                      placeholder="City"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group
                  label="State"
                  label-for="v-state"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="state"
                    rules="required"
                  >
                    <b-form-select
                      id="h-category"
                      v-model="contact.state_id"
                      :options="state_options"
                      :state="errors.length > 0 ? false:null"
                      @change="filterState"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="2">
                <b-form-group
                  label="Zip code"
                  label-for="v-zip_code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="zip_code"
                    rules="required"
                  >
                    <b-form-input
                      id="v-zip_code"
                      v-model="contact.zip_code"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Zip code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group
                  label="County"
                  label-for="v-county"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="county"
                    rules="required"
                  >
                    <b-form-select
                      id="v-county"
                      v-model="contact.county_id"
                      :options="county_options"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="mt-4 d-flex align-items-center justify-content-between">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>

              <b-button
                v-if="is_update"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                @click="deleteData"
              >
                Delete
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                type="submit"
                :disabled="is_edited === false && is_update ? true : false"
              >
                Save
              </b-button>
            </div>
          </b-form>

        </validation-observer>

      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar, BButton, BModal, VBModal, BCardText, BFormSelect, BRow, BCol, BFormGroup, BFormInput, BForm, BPagination,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import SortDown from '../../../assets/images/icons/sortdown.png'
import SortUp from '../../../assets/images/icons/sortup.png'

export default {
  components: {
    BAvatar,
    BButton,
    BModal,
    BCardText,
    BRow,
    BCol,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BPagination,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      SortUp,
      SortDown,
      email,
      contact: {
        full_name: '',
        email: '',
        phone: '',
        address: '',
        state: '',
        city: '',
        zip_code: '',
        county_id: null,
      },
      filter: null,
      sortBy: 'id',
      sortDesc: false,
      filterOn: [],
      perPage: 12,
      pageOptions: [3, 5, 10],
      currentPage: 1,
      required,
      page: 1,
      searchTerm: '',
      sort_type: 'DESC',
      is_edited: false,
    }
  },
  computed: {
    contacts() {
      const items = this.$store.state.contact.contacts
      return items.filter(item => item.full_name.toLowerCase().includes(this.searchTerm.toLowerCase()))
    },
    paginatedData: {
      get() {
        const start = (this.currentPage - 1) * this.perPage
        const end = start + this.perPage
        return this.contacts.slice(start, end)
      },
      set(value) {
        return value
      },
    },
    userData() {
      return this.$store.state.user.profile
    },
    totalRows() {
      return this.contacts.length
    },
    states() {
      return this.$store.state.state.states
    },
    state_options() {
      return this.states.map(item => {
        const data = { value: item.id, text: `${item.name}` }
        return data
      })
    },
    counties() {
      return this.$store.state.county.counties
    },
    county_options() {
      return this.counties.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
  },
  watch: {
    contact: {
      handler(oldValue, newValue) {
        if (newValue && this.is_update) {
          this.is_edited = true
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getContacts()
  },
  methods: {
    getContacts() {
      return this.$store.dispatch('contact/getMemberContacts', this.userData.id)
    },
    createContact() {
      this.$refs.contactForm.validate().then(success => {
        if (success) {
          const data = this.contact
          data.member_id = this.userData.id
          this.$store.dispatch('contact/createContact', data)
          this.hide()
        }
      })
    },
    filterState(id) {
      return this.$store.dispatch('county/getCounties', id)
    },
    onPageChanged(page) {
      this.currentPage = page
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      this.paginatedData = this.contacts.slice(start, end)
    },
    updateContact() {
      this.$refs.contactForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('contact/updateContact', this.contact)
          this.is_edited = false
          this.$bvModal.hide('app-modal')
          const dom = this
          setTimeout(() => {
            dom.emptyData()
          }, 300)
        }
      })
    },
    sortData() {
      if (this.sort_type === 'DESC') {
        this.sort_type = 'ASC'
      } else if (this.sort_type === 'ASC') {
        this.sort_type = 'DESC'
      }
      this.$store.dispatch('contact/sortContact', { data: this.contacts, sort_type: this.sort_type })
    },
    deleteData() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('contact/deleteContact', this.contact)
          this.hide()
        }
      })
    },
    emptyData() {
      this.contact = {
        full_name: '', email: '', phone: '', state_id: null, address: '', city: '', zip_code: '',
      }
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide(e) {
      if (this.is_edited === true) {
        e.preventDefault()
        this.$swal({
          title: 'Do you want to save changes',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save Changes',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.is_edited = false
            this.updateContact()
          } else if (result.dismiss === 'cancel') {
            this.is_update = false
            this.is_edited = false
            this.$bvModal.hide('app-modal')
            this.emptyData()
          }
        })
      } else {
        this.is_update = false
        this.$bvModal.hide('app-modal')
        this.is_edited = false
        const dom = this
        setTimeout(() => {
          dom.emptyData()
        }, 300)
      }
    },
    showDetails(item) {
      this.is_update = true
      this.contact = item
      this.$bvModal.show('app-modal')
      const dom = this
      setTimeout(() => {
        dom.is_edited = false
      }, 1000)
    },
  },
}
</script>

<style scoped>

</style>
