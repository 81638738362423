<template>
  <section>
    <ServicesTopBar />
    <MemberContact />
    <SiteFooter />
  </section>
</template>

<script>
import ServicesTopBar from '../components/services/ServicesTopBar.vue'
import MemberContact from '../components/services/MemberContact.vue'
import SiteFooter from '../components/site/SiteFooter.vue'

export default {
  components: { ServicesTopBar, MemberContact, SiteFooter },
}
</script>

<style scoped>

</style>
